









































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import PDFViewer from '@/components/pdf/PDFViewer.vue';
import moment from 'moment';
import _ from 'lodash';
import Axios from 'axios';
import print from 'print-js';
import { BASE_API_URL, EventBus } from '@/config';


@Component({
    components: {
        PDFViewer
    }
})
export default class ViewDocument extends Vue {

    @Prop({ required: true }) readonly document;
    @Prop() readonly loanTxnId;
    @Prop({ default: () => [] }) readonly borrowers;
    @Prop({ default: false }) readonly showDeleteButton;
    @Prop({ default: false }) readonly allowUpload;
    @Prop({ default: false }) readonly allowUploadForWetSign;

    currentHistoryIndex: number = 0;
    currentVersionIndex: number = 0;
    tabs = [];
    documentLists = [];

    @Watch('document', { immediate: true })
    documentUpdated() {
        this.changeToLatestVersion();
        this.prepareDocumentLists();
    }

    $refs!: {
        fileinput: any
    };

    get showTabs() {
        return _.get(this, 'documentLists', []).length > 1;
    }

    get currentVersion() {
        if (!_.get(this.documents, 'fileHistory', []).length) { return null; }
        return _.get(this.documents, 'fileHistory', [])[this.currentVersionIndex];
    }

    get file() {
        if (!this.currentVersion) { return null; }
        return _.last(this.currentVersion.files);
    }

    get documents() {
        if (!this.documentLists.length) { return [] }
        return this.documentLists[this.currentHistoryIndex];
    }

    get wetSignBorrower() {
        return _.get(this.documents, 'borrowerId');
    }

    get fileExtension() {
        if (!_.get(this.file, 'path', '')) { return ''; }

        const [ , extension ] = (/(?:\.([^.]+))?$/).exec(_.get(this.file, 'path', ''));

        return extension;
    }

    get showUpload() {
        if (!this.loanTxnId) {
            return false;
        }
        
        if (this.allowUpload) {
            return true;
        }

        if (this.wetSignBorrower && this.allowUploadForWetSign) {
            return true;
        }

        return false;
    }

    get isPDF() { return this.fileExtension === 'pdf'; }
    get isImage() { return [ 'gif', 'jpg', 'jpeg', 'png' ].includes(this.fileExtension); }

    get canPrint() {
        if (!_.get(this.file, 'path', '')) { return false; }

        return this.isPDF || this.isImage;
    }

    changeVersion(index) {
        this.currentVersionIndex = index;
    }

    changeHistory(index) {
        this.currentHistoryIndex = index;
        this.changeToLatestVersion();
    }

    changeToLatestVersion() {
        const currentFileHistoryLength = _.get(this.documents, 'fileHistory', []).length;
        if (currentFileHistoryLength) { this.changeVersion(currentFileHistoryLength - 1) ; }
    }

    newFileEvent() {
        const fileInput = this.$refs.fileinput;

        if (!fileInput) { return; }

        fileInput.click();
    }

    async uploadFile(event) {
        try {
            this.$store.state.wemloLoader = true;

            const file: Blob = _.head(event.target.files);

            if (!file) {
                return;
            }

            const formData = new FormData();

            formData.append('file', file);
            formData.append('id', _.get(this.document, '_id', null));
            formData.append('loanTxnId', this.loanTxnId);
            formData.append('forBorrower', this.documents.borrowerId);
            formData.append('userId', this.$userId);
            formData.append('manuallyUploaded', 'true');

            const { status } = await Axios.post(BASE_API_URL + 'pos/uploadLoanDocument', formData);

            if (status !== 200) {
                throw new Error('Error uploading file');
            }

            this.$emit('fileUploaded');
            this.$store.state.wemloLoader = false;
            this.$snotify.success("Document Uploaded Successfully");
        } catch (error) {
            this.$store.state.wemloLoader = false;
            console.error('ViewDocument:uploadFile', error);
        }
    }

    async mounted() {
        this.changeToLatestVersion();
        this.prepareDocumentLists();
        EventBus.$on('documentVersionDeleted', this.documentVersionDeletedHandler);
    }

    beforeDestroy() {
        EventBus.$off('documentVersionDeleted', this.documentVersionDeletedHandler);
    }

    documentVersionDeletedHandler(documentId) {
        this.documents.fileHistory = this.documents.fileHistory.filter(document => document._id !== documentId);
        if(!this.documents.fileHistory.length) {
            this.$modal.hide('viewDocumentModal');
        }
    }

    prepareDocumentLists() {
        this.documentLists = [];

        const fileHistory = _.get(this.document, 'fileHistory', []);
        const fileHistoryForBorrower = _.get(this.document, 'fileHistoryForBorrower', []);

        if (fileHistory.length) {
            this.documentLists.push({
                label: _.get(this.document, 'categoryType', '') === 'Broker Disclosures' ? 'Wesign Documents' : 'Documents',
                borrowerId: null,
                fileHistory
            });
        }

        fileHistoryForBorrower.forEach((history, index) => {
            
            this.documentLists.push({
                label: this.getBorrowerName(history.borrowerId, index),
                borrowerId: history.borrowerId,
                fileHistory: history.fileHistory
            });
        })
    }

    async printFile() {
        if (!this.canPrint) { return; }
        
        const path = _.get(this.file, 'path', null);

        this.$store.state.wemloLoader = true;
        
        try {

            const { data: fileObject } = await Axios.post(
                BASE_API_URL + 'common/downloadPDF',
                { data: path },
                { responseType: "blob" }
            );

            const objectURL = URL.createObjectURL(fileObject);

            if (this.isPDF) {
                print(objectURL);
            }

            if (this.isImage) {
                print({
                    printable: objectURL,
                    type: 'image',
                    imageStyle: 'width: 100%',
                });
            }
        } catch (error) { }

        this.$store.state.wemloLoader = false;
    }

    emitDeleteEvent() {
        this.$emit('delete', {
            documentId: _.get(this.document, '_id'),
            fileHistoryId: _.get(this.currentVersion, '_id'),
            wetSignBorrower: this.wetSignBorrower,
        });
    }

    getBorrowerName(borrowerId, index) {
        const { firstName, lastName } = this.borrowers.find(borrowerItem => _.get(borrowerItem, 'userId', '') === borrowerId);
        return (firstName || lastName) ? `${firstName} ${lastName}` : `Borrower ${index + 1}`;
    }

    pad(number, digits) {
        return Array(Math.max(digits - String(number).length + 1, 0)).join('0') + number;
    }

    formatDate(date) {
        return moment(date).format('MM-DD-Y');
    }
}
